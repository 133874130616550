export const env = process.env.NEXT_PUBLIC_ENV || process.env.NODE_ENV

export const IMG_CDN_URL =
  env === 'production'
    ? 'https://ik.imagekit.io/cssbattle/'
    : 'https://ik.imagekit.io/cssbattle/staging/'

// prev daily targetscount will be number of days since june 2023
const diffTime = Math.abs(new Date() - new Date('2023-06-01'))
export const DAILY_TARGET_COUNT = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
export const DAILY_TARGET_COUNT_APPROX =
  Math.floor(DAILY_TARGET_COUNT / 100) * 100
