import React, {forwardRef} from 'react'
import {Loader} from './Loader'

export const Button = forwardRef(
  (
    {
      type = 'button',
      primary,
      danger,
      success,
      cool,
      size,
      extraClasses = '',
      children,
      isLoading,
      noTransition = false,
      tooltipClass = '',
      ...rest
    },
    ref
  ) => {
    return (
      <button
        type={type}
        ref={ref}
        className={`button ${tooltipClass} ${primary ? 'button--primary' : ''} 
      ${cool ? 'button--cssbattle' : ''}
      ${size ? `button--${size}` : ''}
      ${success ? 'button--success' : ''} ${
          danger ? 'button--danger' : ''
        } ${extraClasses}`}
        disabled={isLoading}
        data-transition={!noTransition}
        {...rest}
      >
        {children}
        <div
          style={{
            display: 'flex',
            transition: 'max-width 0.3s ease',
            overflow: 'hidden',
            maxWidth: isLoading ? '1.6em' : 0,
          }}
        >
          <Loader height="1.2em" leftMargin="0.5rem" />
        </div>
      </button>
    )
  }
)
