import React, {useEffect, useRef, useState, forwardRef} from 'react'
import {createPortal} from 'react-dom'
import {useWhenMounted} from './hooks/useWhenMounted'

const Dropdown = ({
  dropdownContainerClasses = '',
  dropdownBtnClasses = '',
  dropdownMenuClasses = '',
  dropdownBtnContent,
  alignRight,
  alignTop,
  isFixed,
  onOpen,
  minimal,
  fullWidth = false,
  direction = 'bottom', // bottom, left, right, top
  ignoreScrollTop = false,
  children,
  disabled = false,
}) => {
  const triggerRef = useRef()
  const dropdownRef = useRef()
  const [isOpen, setIsOpen] = useState(false)

  const [x, setX] = useState()
  const [y, setY] = useState()
  const [width, setWidth] = useState()

  const getPosition = () => {
    if (triggerRef.current == null) return

    var box = triggerRef.current.getBoundingClientRect()
    var parentBox = triggerRef.current?.parentElement?.getBoundingClientRect()

    var body = document.body
    var docEl = document.documentElement

    var scrollTop = ignoreScrollTop
      ? 0
      : window.pageYOffset || docEl.scrollTop || body.scrollTop
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

    var clientTop = docEl.clientTop || body.clientTop || 0
    var clientLeft = docEl.clientLeft || body.clientLeft || 0

    var top = box.top + scrollTop - clientTop + box.height + 8
    top = isFixed ? top - scrollTop : top

    var left = box.left + scrollLeft - clientLeft
    var parentWidth = parentBox?.width

    const bodyWidth = document.body.clientWidth
    const bodyHeight = document.body.clientHeight
    const triggerWidth = triggerRef.current.clientWidth
    const triggerHeight = triggerRef.current.clientHeight
    const triggerX = box.left
    const triggerY = box.top

    if (alignRight) {
      left = bodyWidth - triggerX - triggerWidth - scrollLeft + clientLeft
    }
    if (alignTop) {
      top = bodyHeight - triggerY - scrollTop + clientTop
    }

    // TODO: implement left direction
    switch (direction) {
      case 'top':
        top = bodyHeight - box.top - scrollTop + clientTop + 8
        break
      case 'right':
        top = box.top + scrollTop - clientTop
        left = triggerX + triggerWidth + scrollLeft + clientLeft + 8
        break
      default:
        break
    }

    setX(left)
    setY(top)
    setWidth(parentWidth)
  }

  function clickHandler(event) {
    if (!isOpen) return
    if (dropdownRef.current.contains(event.target)) return
    if (triggerRef.current.contains(event.target)) return

    setIsOpen(false)
  }

  useEffect(() => {
    getPosition()

    if (isOpen) {
      const dropdown = dropdownRef.current.querySelector('.dropdown-menu')
      dropdown.setAttribute('tabindex', '0')
      dropdown.focus()
    }

    document.addEventListener('click', clickHandler, false)
    return () => {
      document.removeEventListener('click', clickHandler)
    }
  }, [isOpen])

  useEffect(() => {
    window.addEventListener('resize', getPosition)
  }, [])

  // useEffect(() => {
  //   window.addEventListener("scroll", getPosition);
  // }, []);

  function toggleHandler(e) {
    if (
      e.target.tagName === 'INPUT' ||
      e.target.tagName === 'LABEL' ||
      e.target.dataset.dropdownInteractive
    )
      return

    setIsOpen(!isOpen)
    if (onOpen && !isOpen) onOpen()
  }

  return (
    <button
      type="button"
      ref={triggerRef}
      className={`dropdown-container ${dropdownContainerClasses}`}
      onClick={toggleHandler}
      disabled={disabled}
    >
      <div
        className={`dropdown-btn ${minimal ? 'dropdown-btn--minimal' : ''} ${
          direction === 'right' ? 'dropdown-btn--dir-right' : ''
        } ${dropdownBtnClasses}`}
      >
        {dropdownBtnContent}
      </div>
      <DropdownBody ref={dropdownRef} isOpen={isOpen}>
        <div
          style={{
            position: isFixed ? 'fixed' : 'absolute',
            left: alignRight ? 'auto' : x + 'px',
            top: alignTop ? 'auto' : y + 'px',
            bottom: alignTop ? y + 'px' : 'auto',
            right: alignRight ? x + 'px' : 'auto',
            width: fullWidth ? width : 'null',
          }}
          className={`dropdown-menu  ${dropdownMenuClasses}`}
        >
          {children}
        </div>
      </DropdownBody>
    </button>
  )
}

const DropdownBody = forwardRef(function DropdownBody(props, ref) {
  const {isOpen, children} = props
  const isMounted = useWhenMounted()

  if (isMounted && isOpen) {
    return createPortal(
      <div ref={ref}>{children}</div>,
      document.getElementById('portal-root')
    )
  } else {
    return null
  }
})

export {Dropdown, DropdownBody}
