const BattleTypes = {
  BIG_BATTLE: 'big',
  MINI_BATTLE: 'mini',
}

const BattleStatuses = {
  UPCOMING: 'upComing',
  ONGOING: 'onGoing',
  FINISHED: 'finished',
}

const BattleGameModes = {
  CODE_GOLF: 'codegolf',
  FIRST_TO_MATCH: 'firstToMatch',
}

const battles = [
  {
    id: 1,
    slug: 'pilot-battle',
    name: 'Pilot Battle',
    type: BattleTypes.BIG_BATTLE,
    levelIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    startDate: 'Apr 4 2019 16:30:00 GMT+0530',
    endDate: 'May 9 2019 16:30:00 GMT+0530',
    description:
      'First ever battle of CSSBattle. 12 fun warm-up targets to play and climb the leaderboards.',
  },

  {
    id: 2,
    slug: 'visibility',
    name: 'Visibility',
    type: BattleTypes.BIG_BATTLE,
    levelIds: [13, 14, 15, 16, 17, 18],
    startDate: 'May 9 2019 16:30:00 GMT+0530',
    endDate: 'May 30 2019 16:30:00 GMT+0530',
    description:
      '6 targets unlock across 3 weeks. Recreate them in the smallest possible CSS code and climb the leaderboards.',
  },

  {
    id: 3,
    slug: 'cursor',
    name: 'Cursor',
    type: BattleTypes.MINI_BATTLE,
    levelIds: [19, 20],
    startDate: 'Jun 1 2019 16:30:00 GMT+0530',
    endDate: 'Jun 3 2019 16:30:00 GMT+0530',
    description:
      'First ever mini-battle. 48 hours, 2 Targets. Some intense CSS golfing.',
  },

  {
    id: 4,
    slug: 'display',
    name: 'Display',
    type: BattleTypes.BIG_BATTLE,
    levelIds: [21, 22, 23, 24, 25, 26, 27, 28],
    startDate: 'Jun 12 2019 16:30:00 GMT+0530',
    endDate: 'Jul 3 2019 16:30:00 GMT+0530',
    description:
      '8 targets unlock across 3 weeks. Recreate them in the smallest possible CSS code and climb the leaderboard!',
    sponsor: {
      name: 'SitePoint',
      link: 'https://cssbattle.dev/api/adRedirect?url=https%3A%2F%2Fwww.sitepoint.com%3Futm_source%3Dcss-battles%26utm_medium%3Dreferral%26utm_campaign%3Dlogo-battle&sponsorId=2',
    },
    rewards: {
      gold: '$50',
      silver: '$30',
      bronze: '$20',
      others: 'Top 20: SitePoint Premium',
    },
  },

  {
    id: 5,
    slug: 'inline',
    name: 'Inline',
    type: BattleTypes.MINI_BATTLE,
    levelIds: [29, 30],
    startDate: 'Jul 13 2019 16:30:00 GMT+0530',
    endDate: 'Jul 15 2019 16:30:00 GMT+0530',
    description: '48 hours, 2 Targets and some intense CSS golfing.',
  },

  {
    id: 6,
    slug: 'conic',
    name: 'Conic',
    type: BattleTypes.MINI_BATTLE,
    levelIds: [31, 32],
    startDate: 'Jul 19 2019 16:30:00 GMT+0530',
    endDate: 'Jul 21 2019 16:30:00 GMT+0530',
    description: '48 hours, 2 Targets and some intense CSS golfing.',
  },

  {
    id: 7,
    slug: 'backface',
    name: 'Backface',
    type: BattleTypes.BIG_BATTLE,
    levelIds: [33, 34, 35, 36, 37, 38, 39, 40, 41],
    startDate: 'Aug 01 2019 16:30:00 GMT+0530',
    endDate: 'Aug 22 2019 16:30:00 GMT+0530',
    description:
      '9 targets unlock across 3 weeks. Recreate them with smallest possible CSS code and climb the leaderboard!',
  },

  {
    id: 8,
    slug: 'transition',
    name: 'Transition',
    type: BattleTypes.MINI_BATTLE,
    levelIds: [42, 43, 44],
    startDate: 'Sep 07 2019 16:30:00 GMT+0530',
    endDate: 'Sep 09 2019 16:30:00 GMT+0530',
    description: '3 fun targets for the weekend 😀',
  },

  {
    id: 9,
    slug: 'margin',
    name: 'Margin',
    type: BattleTypes.MINI_BATTLE,
    levelIds: [45, 46],
    startDate: 'Dec 12 2019 16:30:00 GMT+0530',
    endDate: 'Dec 14 2019 16:30:00 GMT+0530',
    description:
      '2 fun targets to flex your muscles for the next big battle 🔥',
  },

  {
    id: 10,
    slug: 'block',
    name: 'Block',
    type: BattleTypes.BIG_BATTLE,
    levelIds: [47, 48, 49, 50, 51, 52],
    startDate: 'Mar 26 2020 16:30:00 GMT+0530',
    endDate: 'Apr 9 2020 16:30:00 GMT+0530',
    description:
      '6 targets to battle out over 2 weeks and learn about best practices against Corona Virus 🔥',
  },

  {
    id: 11,
    slug: 'overflow',
    name: 'Overflow',
    type: BattleTypes.BIG_BATTLE,
    levelIds: [53, 54, 55, 56, 57, 58, 59, 60],
    startDate: 'Jun 9 2020 16:30:00 GMT+0530',
    endDate: 'Jul 9 2020 16:30:00 GMT+0530',
    description:
      '8 targets (2 per week) to battle out over 4 weeks and win some cool rewards!',
    sponsor: {
      name: 'Pastel',
      link: 'https://cssbattle.dev/api/adRedirect?url=https%3A%2F%2Fusepastel.com%3Futm_source%3Dcssbattle%26utm_campaign%3Dcssbattle_sponsorship&sponsorId=3',
    },
    rewards: {
      gold: '$50',
      silver: '$30',
      bronze: '$20',
      others: 'Top 10: 1-year free Pastel Premium',
    },
  },

  {
    id: 12,
    slug: 'blend',
    name: 'Blend',
    type: BattleTypes.BIG_BATTLE,
    levelIds: [61, 62, 63, 64, 65, 66, 67, 68],
    openLevelIds: [62],
    startDate: 'Nov 21 2020 16:30:00 GMT+0530',
    endDate: 'Dec 20 2020 16:30:00 GMT+0530',
    description:
      '8 targets (2 per week, and a new twist) to battle out over 4 weeks and win some cool rewards!',
    sponsor: {
      name: 'ImprovMX',
      link: 'https://cssbattle.dev/api/adRedirect?url=https%3A%2F%2Fimprovmx.com%3Futm_source%3Dcssbattle%26utm_campaign%3Dcssbattle_sponsorship&sponsorId=4',
    },
    rewards: {
      gold: '$80 + ImprovMX premium for 1337mo',
      silver: '$55 + ImprovMX premium for 42mo',
      bronze: '$40 + ImprovMX premium for 24mo',
      others: '4th-10th: $25 + ImprovMX premium for 12mo',
      note: 'Cash prizes will be given in the form of Amazon Gift Cards',
    },
  },

  {
    id: 13,
    slug: 'clip',
    name: 'Clip',
    type: BattleTypes.BIG_BATTLE,
    levelIds: [69, 70, 71, 72, 73, 74, 75, 76],
    openLevelIds: [69, 71, 73],
    startDate: 'Mar 02 2021 16:30:00 GMT+0530',
    endDate: 'Mar 30 2021 16:30:00 GMT+0530',
    rewards: {
      others: 'Top 10: Customized limited edition CSSBattle Merchandise',
    },
    description:
      '8 targets (with some open ones!) to battle out over 4 weeks and win some cool rewards!',
    sponsor: {
      name: 'PushOwl',
      link: 'https://cssbattle.dev/api/adRedirect?url=https%3A%2F%2Fpushowl.com%3Futm_source%3Dcssbattle%26utm_campaign%3Dcssbattle_sponsorship&sponsorId=5',
    },
  },

  {
    id: 14,
    slug: 'zindex',
    name: 'ZIndex',
    type: BattleTypes.MINI_BATTLE,
    levelIds: [77, 78, 79, 80],
    startDate: 'May 25 2021 16:30:00 GMT+0530',
    endDate: 'May 29 2021 16:30:00 GMT+0530',
    description:
      '4 days, 1 new target everyday 🎯! Ultimate crash course for CSS golfing!',
  },

  {
    id: 15,
    slug: 'filter',
    name: 'Filter',
    type: BattleTypes.BIG_BATTLE,
    levelIds: [81, 82, 83, 84, 85, 86, 87, 88],
    openLevelIds: [84],
    startDate: 'Sep 21 2021 16:30:00 GMT+0530',
    endDate: 'Oct 21 2021 16:30:00 GMT+0530',
    description:
      '8 targets (with some open ones!) to battle out over 4 weeks and level up your CSS!',
    sponsor: {
      name: 'Odoo',
      link: 'https://cssbattle.dev/api/adRedirect?url=https%3A%2F%2Fwww.odoo.com%2Fapp%2Fwebsite%3Futm_source%3DCSSBattle%26utm_medium%3Dcpc-social%26utm_campaign%3Dwebsite&sponsorId=6',
    },
  },

  {
    id: 16,
    slug: 'aspect',
    name: 'Aspect',
    type: BattleTypes.BIG_BATTLE,
    levelIds: [89, 90, 91, 92, 93, 94, 95, 96],
    openLevelIds: [91],
    startDate: 'Oct 28 2021 16:30:00 GMT+0530',
    endDate: 'Nov 28 2021 16:30:00 GMT+0530',
    description:
      'Create 8 targets (with some open ones!) over 4 weeks and level up your CSS!',
  },

  {
    id: 17,
    slug: 'christmas',
    name: 'Christmas 🎄',
    type: BattleTypes.MINI_BATTLE,
    levelIds: [97, 98, 99, 100],
    openLevelIds: [100],
    startDate: 'Dec 22 2021 16:30:00 GMT+0530',
    endDate: 'Dec 26 2021 16:30:00 GMT+0530',
    description:
      "This festive season, let's celebrate Christmas with these 4 merry targets and some joyful CSS! 🎄🎅🏼",
  },

  {
    id: 18,
    slug: 'float',
    name: 'Float',
    type: BattleTypes.BIG_BATTLE,
    levelIds: [101, 102, 103, 104, 105, 106, 107, 108],
    openLevelIds: [106],
    startDate: 'Mar 10 2022 16:30:00 GMT+0530',
    endDate: 'Apr 09 2022 16:30:00 GMT+0530',
    description:
      "First battle of the year! Let's get into the CSS art mood with some nice Anime targets!",
  },

  {
    id: 19,
    slug: 'spacing',
    name: 'Spacing',
    type: BattleTypes.BIG_BATTLE,
    levelIds: [109, 110, 111, 112, 113, 114, 115, 116],
    openLevelIds: [111],
    startDate: 'Jun 07 2022 16:30:00 GMT+0530',
    endDate: 'Jul 05 2022 16:30:00 GMT+0530',
    description: '4 weeks full of CSS art! 8 fun targets coming your way!',
  },

  {
    id: 20,
    slug: 'hover',
    name: 'Hover',
    type: BattleTypes.BIG_BATTLE,
    levelIds: [117, 118, 119, 120, 121, 122, 123, 124],
    openLevelIds: [120],
    startDate: 'Aug 04 2022 16:30:00 GMT+0530',
    endDate: 'Sep 02 2022 16:30:00 GMT+0530',
    description:
      '4 weeks full of CSS art! 8 fun targets coming your way in our 20th battle!',
    sponsor: {
      name: 'Arineo',
      link: 'https://cssbattle.dev/api/adRedirect?url=https%3A%2F%2Farineo.com%2Fen%2F&sponsorId=7',
    },
    rewards: {
      others: 'Top 20: Limited-Edition CSSBattle Stickers!',
    },
  },

  {
    id: 21,
    slug: 'rotate',
    name: 'Rotate',
    type: BattleTypes.BIG_BATTLE,
    firstLevelId: 125,
    levelIds: [125, 126, 127, 128, 129, 130, 131, 132],
    openLevelIds: [130],
    startDate: 'Sep 15 2022 22:00:00 GMT+0530',
    endDate: 'Oct 13 2022 22:00:00 GMT+0530',
    description:
      '4 weeks full of crazy letter art! Time to do some CSS art calligraphy.',
    sponsor: {
      name: 'RootLearn',
      link: 'https://cssbattle.dev/api/adRedirect?url=https%3A%2F%2Fwww.rootlearn.com%2Fbootcamp%2Fweb-dev%3Fsource%3Dcssbattle&sponsorId=8',
    },
  },

  {
    id: 22,
    slug: 'grid',
    name: 'Grid',
    type: BattleTypes.BIG_BATTLE,
    firstLevelId: 133,
    levelIds: [133, 134, 135, 136, 137, 138, 139, 140],
    openLevelIds: [137],
    startDate: 'Nov 29 2022 22:00:00 GMT+0530',
    endDate: 'Dec 27 2022 22:00:00 GMT+0530',
    description:
      'Let’s celebrate the end of the year with some festive targets and some joyful CSS!',
  },

  {
    id: 23,
    slug: 'contain',
    name: 'Contain',
    type: BattleTypes.MINI_BATTLE,
    firstLevelId: 141,
    levelIds: [141, 142],
    openLevelIds: [142],
    startDate: 'Mar 18 2023 22:00:00 GMT+0530',
    endDate: 'Mar 20 2023 22:00:00 GMT+0530',
    description:
      'New year, new rules! Join us in this mini battle to kickstart the year!',
  },

  {
    id: 24,
    slug: 'offset',
    name: 'Offset',
    type: BattleTypes.BIG_BATTLE,
    firstLevelId: 143,
    levelIds: [143, 144, 145, 146, 147, 148, 149, 150],
    openLevelIds: [148],
    startDate: 'May 01 2023 22:00:00 GMT+0530',
    endDate: 'Jun 01 2023 22:00:00 GMT+0530',
    description:
      '4 weeks full of CSS art! 8 corner-themed fun targets coming your way!',
  },

  {
    id: 25,
    slug: 'flex',
    name: 'Flex',
    type: BattleTypes.BIG_BATTLE,
    firstLevelId: 151,
    levelIds: [151, 152, 153, 154, 155, 156, 157, 158],
    openLevelIds: [],
    startDate: 'Jun 07 2023 22:00:00 GMT+0530',
    endDate: 'Jul 05 2023 22:00:00 GMT+0530',
    description: '4 weeks full of CSS art! 8 fun targets coming your way!',
  },

  {
    id: 26,
    slug: 'initial',
    name: 'Initial',
    type: BattleTypes.BIG_BATTLE,
    firstLevelId: 159,
    levelIds: [159, 160, 161, 162, 163, 164, 165, 166],
    openLevelIds: [],
    startDate: 'Aug 24 2023 22:00:00 GMT+0530',
    endDate: 'Sep 21 2023 22:00:00 GMT+0530',
    description:
      'Time to flex your CSS art skills! 8 fun targets coming your way!',
  },

  {
    id: 27,
    slug: 'relative',
    name: 'Relative',
    type: BattleTypes.MINI_BATTLE,
    firstLevelId: 167,
    levelIds: [167, 168, 169, 170],
    openLevelIds: [],
    startDate: 'Sep 22 2023 22:00:00 GMT+0530',
    endDate: 'Sep 29 2023 22:00:00 GMT+0530',
    description: 'A chill mini battle with 4 targets!',
    targetBatches: [4],
    sponsor: {
      name: 'React India 2023 conference',
      link: 'https://cssbattle.dev/api/adRedirect?url=https%3A%2F%2Freactindia.io%3Futm_source%3Dcssbattle%26utm_campaign%3Dcssbattle_sponsorship&sponsorId=9',
    },
  },

  {
    id: 28,
    slug: 'revert',
    name: 'Revert',
    type: BattleTypes.BIG_BATTLE,
    firstLevelId: 171,
    levelIds: [171, 172, 173, 174, 175, 176, 177, 178],
    openLevelIds: [],
    startDate: 'Oct 13 2023 22:00:00 GMT+0530',
    endDate: 'Nov 10 2023 22:00:00 GMT+0530',
    description:
      "It's Halloween month! Let's celebrate the festival with some spooky CSS art!",
  },

  {
    id: 29,
    slug: 'font',
    name: 'Font',
    type: BattleTypes.BIG_BATTLE,
    firstLevelId: 179,
    levelIds: [179, 180, 181, 182, 183, 184, 185, 186],
    openLevelIds: [],
    targetBatches: [2, 3, 3],
    startDate: 'Dec 01 2023 22:00:00 GMT+0530',
    endDate: 'Dec 22 2023 22:00:00 GMT+0530',

    description:
      "End of year battle. Let's bid adieu to 2023 with some fun CSS flag art!",
  },

  {
    id: 30,
    slug: 'gradient',
    name: 'Gradient',
    type: BattleTypes.BIG_BATTLE,
    firstLevelId: 187,
    levelIds: [187, 188, 189, 190, 191, 192, 193, 194],
    openLevelIds: [],
    startDate: 'Feb 08 2024 22:00:00 GMT+0530',
    endDate: 'Mar 07 2024 22:00:00 GMT+0530',
    description: 'Time to flex your CSS and build some cool CSS art!',
  },

  {
    id: 31,
    slug: 'opacity',
    name: 'Opacity',
    type: BattleTypes.BIG_BATTLE,
    firstLevelId: 195,
    levelIds: [195, 196, 197, 198, 199, 200, 201, 202],
    openLevelIds: [],
    startDate: 'Apr 09 2024 22:00:00 GMT+0530',
    endDate: 'May 07 2024 22:00:00 GMT+0530',
    description: 'Time to flex your CSS and build some cool CSS art!',
    sponsor: {
      name: 'LookAway',
      link: 'https://cssbattle.dev/api/adRedirect?url=https%3A%2F%2Flookaway.app%3Futm_source%3Dcssbattle%26utm_campaign%3Dcssbattle_sponsorship&sponsorId=10',
    },
    rewards: {
      others: 'Top 10: 100% off of 1 Mac license',
    },
  },

  {
    id: 32,
    slug: 'animation',
    name: 'Animation',
    type: BattleTypes.BIG_BATTLE,
    firstLevelId: 203,
    levelIds: [203, 204, 205, 206, 207, 208, 209, 210],
    openLevelIds: [],
    startDate: 'Aug 10 2024 22:00:00 GMT+0530',
    endDate: 'Sep 7 2024 22:00:00 GMT+0530',
    description:
      "Welcome to the superhero-themed battle! Flex your CSS muscles and let's build some amazing CSS art!",
  },

  {
    id: 33,
    slug: 'outline',
    name: 'Outline',
    type: BattleTypes.BIG_BATTLE,
    levelIds: [211, 212, 213, 214, 215, 216, 0, 0],
    firstLevelId: 211,
    startDate: 'Oct 29 2024 22:00:00 GMT+0530',
    endDate: 'Nov 26 2024 22:00:00 GMT+0530',
    description: 'Fun 4 weeks to create 8 targets!',
    sponsor: {
      name: 'CSS Weekly',
      link: 'https://cssbattle.dev/api/adRedirect?url=https%3A%2F%2Fcssw.io%2Fcssweekly-cssbattle&sponsorId=11',
    },
    rewards: {
      gold: 'Top 3: CSS Weekly Sticker pack',
      others: 'Top 10: 60% off on "Mastering Linting" course',
    },
  },
]

function checkIfCustomBattle(battle) {
  return battle.id.length > 6
}

function getBattleStatus(battle) {
  // Duplicate so we can add some new props
  battle = {...battle}

  if (checkIfCustomBattle(battle)) {
    if (!battle.startDate) {
      return BattleStatuses.UPCOMING
    }
    // Transform firebase serverTimestamp into milliseconds timestamp
    battle.startDate = new Date(battle.startDate.seconds * 1000)

    // If there is an endDate, transform and use it. Otherwise calculate one
    // using the startDate and duration.
    battle.endDate = battle.endDate
      ? new Date(battle.endDate.seconds * 1000)
      : +battle.startDate + battle.duration * 60 * 1000
  }
  const now = Date.now()
  const start = new Date(battle.startDate).getTime()
  const end = new Date(battle.endDate).getTime()
  if (now < start) {
    return BattleStatuses.UPCOMING
  }
  if (now > end) {
    return BattleStatuses.FINISHED
  }
  return BattleStatuses.ONGOING
}

module.exports = {
  BattleTypes,
  BattleStatuses,
  BattleGameModes,
  battles,
  checkIfCustomBattle,
  getBattleStatus,
}
